import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./MobileContactModal.css";

const MobileContactModal = (): ReactElement => {
  const { state, dispatch } = useAppContext();

  const {
    contact: { title, body },
    modalCloseButton,
  } = whiteLabelInformation;

  const { mobileContactModalVisible } = state;

  const handleCloseButtonClicked = () => {
    // Toggles the Mobile Contact Modal Visible/Hidden state
    dispatch({ type: ActionType.TOGGLE_MOBILE_CONTACT_VISIBLE });
  };

  return (
    <Modal
      isOpen={mobileContactModalVisible}
      onRequestClose={handleCloseButtonClicked}
      className="Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="MobileContactModalTitle">{parse(title)}</p>
      <p className="MobileContactModalBody">{parse(body)}</p>
      <button className="MobileContactModalButton" onClick={handleCloseButtonClicked}>
        {parse(modalCloseButton)}
      </button>
    </Modal>
  );
};

export default MobileContactModal;
