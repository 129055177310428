import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import Spinner from "../Spinner/Spinner";
import "./LoadingComponent.css";

const LoadingComponent = (): ReactElement => {
  const { state } = useAppContext();
  const { ready } = state;
  const {
    imagePaths: { pageLoadingBackgroundPath },
  } = whiteLabelInformation;

  return (
    <div className={classNames("LoadingComponent", { "LoadingComponent hidden": ready })}>
      <div className="LoadingBackground" style={{ backgroundImage: `url(${pageLoadingBackgroundPath})` }} />
      <Spinner />
    </div>
  );
};

export default LoadingComponent;
