import React, { ReactElement } from "react";
import useAppContext from "../../../../../util/helper";
import "./MobileAnswerButtonsGroup.css";
import MobileAnswerProps from "./MobileAnswerProps";
import MobileQuestion from "../MobileQuestion/MobileQuestion";

const MobileAnswerButtonsGroup = ({ question, answers }: MobileAnswerProps): ReactElement => {
  const { uneeq } = useAppContext();

  /**
   * Sends the clicked answer to Uneeq.
   *
   * @param {string} value Is the clicked response string.
   */
  const sendTextResponse = (value: string) => {
    uneeq.current.sendTranscript(value);
  };

  return (
    <div className="MobileAnswerButtonsGroup">
      <MobileQuestion question={question} />
      {/* Start the AnswerButton grid */}
      <div className="MobileAnswerButtonsGroupGrid">
        {/* We accept up to 6 answers, the rest is disregarded. */}
        {answers.slice(0, 6).map((answer) => {
          return (
            <div
              className="MobileAnswerButtonsGroupAnswerButtons"
              key={answer.value}
              onClick={() => sendTextResponse(answer.value)}
            >
              {answer.label.trim().replace(/^\w/, (c) => c.toUpperCase())}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MobileAnswerButtonsGroup;
