import { useContext } from "react";
import AppContext from "./state/AppContext";
import whiteLabelInformation from "./whitelabel";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useAppContext = (): any => useContext(AppContext);

export const hasDefinedProperty = (object: Record<string, unknown> | undefined, propertyName: string): boolean => {
  return object !== undefined && object?.hasOwnProperty(propertyName) && object[propertyName] !== undefined;
};

// Updates HTML elements
const { applicationPageTitle } = whiteLabelInformation;
export const htmlUpdates = (): void => {
  document.title = applicationPageTitle;
};

export default useAppContext;
