import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement, useState } from "react";
import { useCombobox } from "downshift";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import { ActionType } from "../../../util/state/reducer";

// Load in the medicines list
import rawMedicineList from "../../../resources/medicines.json";
import "./TypeAhead.css";

const TypeAhead = (): ReactElement => {
  const { dispatch, state, uneeq } = useAppContext();
  const { ready } = state;

  const {
    typeAhead: { headerText, sendButtonText },
  } = whiteLabelInformation;

  // Force the medicines list into an alphabetical order, takes localization into account.
  const medicineList = rawMedicineList.sort((a, b) => a.localeCompare(b));

  // Determines the TypeAhead text based on the state.
  const [inputItems, setInputItems] = useState(medicineList);
  const sendMedicineChoice = () => {
    uneeq.current.sendTranscript(state.typeAheadSelectedMedicine);
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(medicineList.filter((medicine) => medicine.toLowerCase().startsWith(inputValue!.toLowerCase())));
    },
    onSelectedItemChange: ({ selectedItem: selectedMedicine }) =>
      dispatch({
        type: ActionType.SET_SELECTED_MEDICINE,
        payload: { typeAheadSelectedMedicine: selectedMedicine },
      }),
  });

  return (
    <div className={classNames("TypeAheadWrapper", { "TypeAheadWrapper hidden": !ready })}>
      <div className="TypeAheadResultListWrapper">
        <ul {...getMenuProps()} className="TypeAheadResultList">
          {isOpen &&
            inputItems.map((item, index) => (
              <li
                style={highlightedIndex === index ? { backgroundColor: "rgba(87, 95, 114, 1)", color: "white" } : {}}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                className="TypeAheadResultListLi"
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </li>
            ))}
        </ul>
      </div>
      <div className="TypeAhead">
        <div className="TypeAheadHeader">{parse(headerText)}</div>
        <div {...getComboboxProps()} className="TypeAheadInputWrapper">
          <input {...getInputProps()} className="TypeAheadInput" />
          <button type="button" {...getToggleButtonProps()} aria-label={"toggle menu"} className="TypeAheadButton">
            {isOpen ? <>&#8595;</> : <>&#8593;</>}
          </button>
        </div>
        <button className="TypeAheadSendButton" onClick={sendMedicineChoice}>
          {parse(sendButtonText)}
        </button>
      </div>
    </div>
  );
};

export default TypeAhead;
