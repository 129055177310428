import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./ButtonsComponent.css";

const ButtonsComponent = (): ReactElement => {
  const { dispatch, state } = useAppContext();
  const { ready } = state;

  const {
    buttonsComponent: { closeButton, settingsButton },
    closeModal: { standardTitle, standardBody },
  } = whiteLabelInformation;

  /**
   * Toggles the close modal shown/hidden state.
   */
  const handleCloseButtonClicked = () => {
    if (!ready) return;

    dispatch({
      type: ActionType.SHOW_CLOSE_MODAL,
      payload: {
        closeModalTitle: standardTitle,
        closeModalBody: standardBody,
      },
    });
  };

  /**
   * Toggles the settings modal shown/hidden state.
   */
  const handleSettingsButtonClicked = () => {
    if (!ready) return;
    dispatch({ type: ActionType.TOGGLE_SETTINGS_MODAL_VISIBLE });
  };

  return (
    <div className={classNames("ButtonsComponent", { "ButtonsComponent hidden": !ready })}>
      <button onClick={handleSettingsButtonClicked}>{parse(settingsButton)}</button>
      <button onClick={handleCloseButtonClicked}>{parse(closeButton)}</button>
    </div>
  );
};

export default ButtonsComponent;
