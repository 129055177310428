import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import { ActionListResponse, ActionListResponseLink } from "../../../util/state/initialState";
import "./MobileActionsList.css";
import MobileSuggestedLink from "./MobileSuggestedLink/MobileSuggestedLink";
import MobileSuggestedQuestion from "./MobileSuggestedQuestion/MobileSuggestedQuestion";

const MobileActionsList = (): ReactElement => {
  const { state } = useAppContext();
  const { actionListResponses, ready } = state;

  if (actionListResponses === undefined) return <div></div>;
  return (
    <div className={classNames("MobileActionsList", { "MobileActionsList hidden": !ready })}>
      {actionListResponses.slice(0, 4).map((action: ActionListResponse) => {
        if (action.type === "suggested-link") {
          const actionLink = action as ActionListResponseLink;
          return (
            <MobileSuggestedLink
              key={actionLink.label}
              label={actionLink.label}
              url={actionLink.url}
              alt={actionLink?.alt}
              highlighted={actionLink.highlighted}
              iconName={actionLink["icon-name"]}
              iconPrefix={actionLink["icon-prefix"]}
            />
          );
        }
        if (action.type === "suggested-question") {
          const actionQuestion = action as ActionListResponse;
          return (
            <MobileSuggestedQuestion
              key={actionQuestion.label}
              label={actionQuestion.label}
              highlighted={actionQuestion.highlighted}
              overrideBackgroundColorHex={actionQuestion["override-font-color-hex"]}
              overrideFontColorHex={actionQuestion["override-font-color-hex"]}
            />
          );
        }
        return <div></div>;
      })}
    </div>
  );
};

export default MobileActionsList;
