import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./PermissionModal.css";

const PermissionModal = (): ReactElement => {
  const { state, dispatch } = useAppContext();

  const {
    permissionModal: { title, body, grantPermissionButton },
    error: { audioTitle, audioBody },
    modalCloseButton,
  } = whiteLabelInformation;

  const { permissionModalVisible } = state;

  /**
   * Asks the user for permission to use their media devices, audio in this case.
   *
   * @returns {Promise<void>}
   */
  const getUserMedia = async (): Promise<void> => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      dispatch({
        type: ActionType.SHOW_ERROR_MODAL,
        payload: { errorTitle: parse(audioTitle), errorBody: parse(audioBody) },
      });
      console.error(`[getUserMedia] getUserMedia error: `, error);
    }
  };

  /**
   * Toggles the permission modal shown/hidden state.
   */
  const handleCloseButtonClicked = () => {
    dispatch({ type: ActionType.TOGGLE_PERMISSION_MODAL_VISIBLE });
  };

  /**
   * Requests permission for using the microphone and starts the conversation.
   */
  const handlePermissionButtonClicked = () => {
    getUserMedia();
    dispatch({ type: ActionType.START_CONVERSATION });
  };

  return (
    <Modal
      isOpen={permissionModalVisible}
      onRequestClose={handleCloseButtonClicked}
      className="PermissionModal Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="PermissionModalTitle">{parse(title)}</p>
      <p className="PermissionModalBody">{parse(body)}</p>
      <button className="PermissionModalButtonGrant" onClick={handlePermissionButtonClicked}>
        {parse(grantPermissionButton)}
      </button>
      <button className="PermissionModalButtonClose" onClick={handleCloseButtonClicked}>
        {parse(modalCloseButton)}
      </button>
    </Modal>
  );
};

export default PermissionModal;
