import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./MobileMenu.css";

const MobileMenu = (): ReactElement => {
  const { state, dispatch } = useAppContext();
  const { mobileMenuVisible } = state;
  const {
    mobileMenu: { settingsButton, privacyButton, contactButton, endButton },
    closeModal: { standardTitle, standardBody },
  } = whiteLabelInformation;

  const handleSettingsTapped = () => {
    dispatch({ type: ActionType.TOGGLE_MOBILE_MENU });
    dispatch({ type: ActionType.TOGGLE_SETTINGS_MODAL_VISIBLE });
  };

  const handlePrivacyTapped = () => {
    dispatch({ type: ActionType.TOGGLE_MOBILE_MENU });
    dispatch({ type: ActionType.TOGGLE_PRIVACY_MODAL_VISIBLE });
  };

  const handleContactTapped = () => {
    dispatch({ type: ActionType.TOGGLE_MOBILE_MENU });
    dispatch({ type: ActionType.TOGGLE_MOBILE_CONTACT_VISIBLE });
  };

  const handleEndTapped = () => {
    dispatch({ type: ActionType.TOGGLE_MOBILE_MENU });
    dispatch({
      type: ActionType.SHOW_CLOSE_MODAL,
      payload: { closeModalTitle: standardTitle, closeModalBody: standardBody },
    });
  };

  return (
    <div className={classNames("MobileMenu", { "MobileMenu-visible": mobileMenuVisible })}>
      <button onClick={handleSettingsTapped}>{parse(settingsButton)}</button>
      <button onClick={handlePrivacyTapped}>{parse(privacyButton)}</button>
      <button onClick={handleContactTapped}>{parse(contactButton)}</button>
      <button onClick={handleEndTapped}>{parse(endButton)}</button>
    </div>
  );
};

export default MobileMenu;
