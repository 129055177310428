import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import "./MobileQuestion.css";
import MobileQuestionProps from "./MobileQuestionprops";

const MobileQuestion = ({ question }: MobileQuestionProps): ReactElement => {
  return (
    <div className="MobileQuestion">
      <FontAwesomeIcon className="MobileQuestionIcon" icon={["fas", "question-circle" as IconName]} size="lg" />
      <div className="MobileQuestionText">{question}</div>
    </div>
  );
};

export default MobileQuestion;
