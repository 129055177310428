import React, { ReactElement } from "react";
import parse from "html-react-parser";
import whiteLabelInformation from "../../../util/whitelabel";
import useAppContext from "../../../util/helper";

import "./PrivacyComponent.css";
import { ActionType } from "../../../util/state/reducer";

const PrivacyComponent = (): ReactElement | null => {
  const { dispatch } = useAppContext();
  const {
    privacy: { readMore, readMoreUnderlined },
    privacyModal: { title, body, subText, subTextUnderlined },
    modalCloseButton,
  } = whiteLabelInformation;

  const isVisible = readMore && readMoreUnderlined && title && body && subText && subTextUnderlined && modalCloseButton;

  const handlePrivacyStatementClick = () => {
    // Toggles the Privacy Modal Shown/Hidden state
    dispatch({ type: ActionType.TOGGLE_PRIVACY_MODAL_VISIBLE });
  };

  return isVisible ? (
    <div className="PrivacyComponentWrapper">
      <div className="PrivacyComponent">
        <p>
          {parse(readMore)}
          <span onClick={handlePrivacyStatementClick}>{parse(readMoreUnderlined)}</span>
        </p>
      </div>
    </div>
  ) : null;
};

export default PrivacyComponent;
