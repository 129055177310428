import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import "./SpaceBar.css";

const SpaceBar = (): ReactElement => {
  const { state } = useAppContext();
  const { sending, recording, ready } = state;

  const {
    spacebar: { sendingTitle, recordingTitle, keepDownTitle },
  } = whiteLabelInformation;

  // Determines the spacebar text based on the state.
  // eslint-disable-next-line no-nested-ternary
  const spacebarText = sending ? sendingTitle : recording ? recordingTitle : keepDownTitle;

  return (
    <div className={classNames("SpaceBar", { "SpaceBar hidden": !ready })}>
      <span>{parse(spacebarText)}</span>
    </div>
  );
};

export default SpaceBar;
