import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import { ActionListHeader } from "../../../../util/state/initialState";
import whiteLabelInformation from "../../../../util/whitelabel";
import "./MobileHeaderText.css";

const MobileHeaderText = (): ReactElement => {
  const { state } = useAppContext();
  const { sending, recording, actionListResponses } = state;

  const {
    mobile: { sendingTitle, recordingTitle, waitingTitle },
  } = whiteLabelInformation;
  // Show the Action Header text if applicable
  const JSONActionHeader = actionListResponses?.find((action: ActionListHeader) => action.type === "header-text");
  // Note: The searched string is currently hardcoded, since this string is also hardcoded in the backend it works for now.
  if (
    JSONActionHeader !== undefined &&
    JSONActionHeader.label &&
    JSONActionHeader.label.trim().includes("Dit gaat over ")
  ) {
    // Set the Header to the header supplied in the JSON
    return (
      <div className="MobileHeaderText">
        <span>{parse(JSONActionHeader.label)}</span>
      </div>
    );
  }

  // Sets the text in the component based on the state.
  if (sending) {
    return (
      <div className="MobileHeaderText">
        <span>{parse(sendingTitle)}</span>
      </div>
    );
  }
  if (recording) {
    return (
      <div className="MobileHeaderText">
        <span>{parse(recordingTitle)}</span>
      </div>
    );
  }
  return (
    <div className="MobileHeaderText">
      <span>{parse(waitingTitle)}</span>
    </div>
  );
};

export default MobileHeaderText;
