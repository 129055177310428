// When holding down spacebar, times shorter that this will trigger
// a message like "Keep holding spacebar while you talk"
export const TAP_THRESHOLD = 700; // ms

// How long can the user be inactive before timeout
export const TIMEOUT = 180 * 1000; // ms

// How close to the end of the timeout should we show the warning
export const SHOW_TIMEOUT = 60 * 1000; // ms
// e.g. TIMEOUT=90sec, SHOW_TIMEOUT=30sec - after 60 secs of inactivity warning will show, after 90 secs sessions ends
