import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import "./AnswerButtonsGroup.css";
import AnswerProps from "./AnswerProps";
import Question from "../Question/Question";

const AnswerButtonsGroup = ({ question, answers }: AnswerProps): ReactElement => {
  const { uneeq } = useAppContext();

  /**
   * Sends the clicked answer to Uneeq.
   *
   * @param {string} value Is the clicked response string.
   */
  const sendTextResponse = (value: string) => {
    uneeq.current.sendTranscript(value);
  };

  return (
    <div className="AnswerButtonsGroup">
      <Question question={question} />
      {/* Start the AnswerButton grid */}
      <div className="AnswerButtonsGroupGrid">
        {/* We accept up to 6 answers, the rest is disregarded. */}
        {answers.slice(0, 6).map((answer) => {
          return (
            <div
              className="AnswerButtonsGroupAnswerButtons"
              key={answer.value}
              onClick={() => sendTextResponse(answer.value)}
            >
              {answer.label.trim().replace(/^\w/, (c) => c.toUpperCase())}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnswerButtonsGroup;
