import parse from "html-react-parser";
import React, { ReactElement, useEffect } from "react";
import Modal from "react-modal";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./CloseModal.css";

const CloseModal = (): ReactElement => {
  const { state, dispatch, uneeq } = useAppContext();

  const {
    closeModalTitle,
    closeModalSubtitle,
    timeLeft,
    closeModalBody,
    closeModalHasTimer,
    closeModalVisible,
  } = state;

  const {
    closeModal: { feedbackURL, yesButton, cancelButton, reloadButton },
  } = whiteLabelInformation;

  /**
   * Redirects user to feedback form, if path provided in whitelabel.
   */
  const handleYesClicked = () => {
    if (feedbackURL === "") window.location.reload();
    else window.location = feedbackURL;
  };

  /**
   * Toggles the close modal shown/hidden state.
   */
  const handleCancelClicked = () => {
    dispatch({ type: ActionType.HIDE_CLOSE_MODAL });
  };

  /**
   * Reload page because Uneeq session has ended.
   */
  const handleReloadClicked = () => {
    window.location.reload();
  };

  useEffect(() => {
    // Pauses and resumes the Uneeq session when the close modal is visible/hidden again.
    const currentUneeq = uneeq.current;
    currentUneeq?.pauseSession();
    return () => {
      // This is called by useEffect when the component is unmounted.
      currentUneeq?.resumeSession();
    };
  }, [uneeq]);

  /**
   * Determines whether the cancel or reload button should be rendered.
   * When time left is 0, the session is stopped. Therefore, the user must reload the page to continue the conversation.
   *
   * @returns A close button component.
   */
  const closeButton = () => {
    return (
      <button className="CloseModalCancelButton" onClick={timeLeft > 0 ? handleCancelClicked : handleReloadClicked}>
        {timeLeft > 0 ? parse(cancelButton) : parse(reloadButton)}
      </button>
    );
  };

  return (
    <Modal
      isOpen={closeModalVisible}
      onRequestClose={timeLeft > 0 ? handleCancelClicked : handleReloadClicked}
      className="CloseModal Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="CloseModalTitle">{parse(closeModalTitle)}</p>
      {closeModalSubtitle !== "" && (
        <p className="CloseModalSubtitle">
          {parse(closeModalSubtitle)} {closeModalHasTimer && <span className="CloseModalTimer">{timeLeft / 1000}</span>}
        </p>
      )}
      <p className="CloseModalBody">{parse(closeModalBody)}</p>
      <button className="CloseModalYesButton" onClick={handleYesClicked}>
        {parse(yesButton)}
      </button>
      {closeButton()}
    </Modal>
  );
};

export default CloseModal;
