import React, { ReactElement } from "react";
import "./Spinner.css";

const Spinner = (): ReactElement => {
  return (
    <div className="Spinner">
      <div className="SpinnerDoubleBounce1"></div>
      <div className="SpinnerDoubleBounce2"></div>
    </div>
  );
};

export default Spinner;
