import whiteLabelJSON from "../config/whitelabel.json";

// Gets the JSON from the configuration file and transforms it into an object.
const whiteLabelObject = whiteLabelJSON;

// TODO: IMPORTANT - Use more elegant and better way to replace the !PUBLIC_URL occurrences with the public URL.

// For the future, use replaceAll() - not yet widely supported.
const whiteLabelInformation = JSON.parse(
  JSON.stringify(whiteLabelObject)
    .split("!PUBLIC_URL")
    .join(process.env.REACT_APP_PUBLIC_URL as string)
);

export default whiteLabelInformation;
