import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import useAppContext from "../../../../util/helper";
import { ActionType } from "../../../../util/state/reducer";
import whiteLabelInformation from "../../../../util/whitelabel";
import "./MobileTutorialModal.css";

const MobileTutorialModal = (): ReactElement => {
  const { state, dispatch } = useAppContext();

  const {
    mobileTutorial: { title, body },
    modalCloseButton,
  } = whiteLabelInformation;
  const { mobileTutorialModalVisible } = state;

  const hasContent = title && body;

  const handleCloseButtonClicked = () => {
    // Toggles the Mobile Tutorial Modal Visible/Hidden state
    dispatch({ type: ActionType.TOGGLE_MOBILE_TUTORIAL_VISIBLE });
  };

  return (
    <Modal
      isOpen={mobileTutorialModalVisible && hasContent}
      onRequestClose={handleCloseButtonClicked}
      className="Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="MobileTutorialModalTitle">{parse(title)}</p>
      <p className="MobileTutorialModalBody">{parse(body)}</p>
      <button className="MobileTutorialModalButton" onClick={handleCloseButtonClicked}>
        {parse(modalCloseButton)}
      </button>
    </Modal>
  );
};

export default MobileTutorialModal;
