import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import "./ContactBox.css";

const ContactBox = (): ReactElement | null => {
  const { state } = useAppContext();
  const { ready } = state;
  const {
    contact: { title, body },
  } = whiteLabelInformation;

  const isVisible = title && body;

  return isVisible ? (
    <div className="ContactBoxWrapper">
      <div className={classNames("ContactBox", { "ContactBox hidden": !ready })}>
        <span>{parse(title)}</span>
        <br />
        <span>{parse(body)}</span>
      </div>
    </div>
  ) : null;
};

export default ContactBox;
