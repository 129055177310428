import React, { ReactElement } from "react";
import parse from "html-react-parser";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";

import "./StartButton.css";

const StartButton = (): ReactElement => {
  const { dispatch } = useAppContext();
  const {
    landingPage: { startButton },
  } = whiteLabelInformation;

  /**
   * Toggles the permission modal shown/hidden state.
   */
  const handleStartButtonTapped = () => {
    dispatch({ type: ActionType.TOGGLE_PERMISSION_MODAL_VISIBLE });
  };

  return (
    <div className="StartButton" onClick={handleStartButtonTapped}>
      <span>{parse(startButton)}</span>
    </div>
  );
};

export default StartButton;
