import React, { ReactElement } from "react";
import "./MobileExplainerButton.css";
import questionMarkIcon from "../../../../assets/img/questionmark-icon.svg";
import useAppContext from "../../../../util/helper";
import { ActionType } from "../../../../util/state/reducer";
// import { ActionType } from "../../../../util/state/reducer";

const MobileExplainerButton = (): ReactElement => {
  const { state, dispatch } = useAppContext();
  const { ready } = state;

  const handleButtonTapped = () => {
    if (!ready) return;
    dispatch({ type: ActionType.TOGGLE_MOBILE_TUTORIAL_VISIBLE });
  };

  return (
    <div className="MobileExplainerButton" onClick={handleButtonTapped}>
      <img src={questionMarkIcon} alt="Question Mark" />
    </div>
  );
};

export default MobileExplainerButton;
