/* eslint-disable prefer-destructuring */
import dayjs from "dayjs";
import { SHOW_TIMEOUT, TAP_THRESHOLD, TIMEOUT } from "../../config/config";
import { hasDefinedProperty } from "../helper";
import { AppState } from "./initialState";
import uneeqMessageHandler, { UneeqAction } from "./uneeqReducer";

// The general reducer action types.
export enum ActionType {
  TOGGLE_PRIVACY_MODAL_VISIBLE = "TOGGLE_PRIVACY_MODAL_VISIBLE",
  TOGGLE_PERMISSION_MODAL_VISIBLE = "TOGGLE_PERMISSION_MODAL_VISIBLE",
  TOGGLE_PRIVACY_ADDITIONAL_VISIBLE = "TOGGLE_PRIVACY_ADDITIONAL_VISIBLE",
  TOGGLE_SETTINGS_MODAL_VISIBLE = "TOGGLE_SETTINGS_MODAL_VISIBLE",
  SHOW_CLOSE_MODAL = "SHOW_CLOSE_MODAL",
  HIDE_CLOSE_MODAL = "HIDE_CLOSE_MODAL",
  TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU",
  TOGGLE_MOBILE_CONTACT_VISIBLE = "TOGGLE_MOBILE_CONTACT_VISIBLE",
  TOGGLE_MOBILE_TUTORIAL_VISIBLE = "TOGGLE_MOBILE_TUTORIAL_VISIBLE",
  SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL",
  HIDE_ERROR_MODAL = "HIDE_ERROR_MODAL",
  START_CONVERSATION = "START_CONVERSATION",
  START_RECORDING = "START_RECORDING",
  STOP_RECORDING = "STOP_RECORDING",
  START_SENDING = "START_SENDING",
  STOP_SENDING = "STOP_SENDING",
  UPDATE_SELECTED_DEVICE = "UPDATE_SELECTED_DEVICE",
  COUNTER_TICK = "COUNTER_TICK",
  HIDE_ALL_MODALS = "HIDE_ALL_MODALS",
  SET_SELECTED_MEDICINE = "SET_SELECTED_MEDICINE",
}
export interface Payload {
  errorTitle?: string;
  errorBody?: string;
  closeModalTitle?: string;
  closeModalBody?: string;
  closeModalSubtitle?: string;
  closeModalHasTimer?: boolean;
  selectedDevice?: SelectedDevice;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
interface Action {
  type: ActionType;
  payload?: Payload;
}

interface SelectedDevice {
  id: string;
  name: string;
}
/**
 * The Gateway for state changes.
 *
 * @param {Appstate} state The current app state.
 * @param {Action | UneeqAction } action The action that determine which reducer case to hit.
 *
 * @returns {AppState} Returns an updated appstate.
 */
const reducer = (state: AppState, action: Action | UneeqAction): AppState => {
  const { payload } = action;
  // Checks whether or not we are dealing with a Uneeq specific message.
  if (action.type === "uneeqMessage") {
    return uneeqMessageHandler(state, action);
  }

  if (action.type !== "COUNTER_TICK") {
    console.info(
      `[reducer] Message received of type "${action.type}" with the following action and pre action state: `,
      action,
      state
    );
  }

  switch (action.type) {
    case ActionType.TOGGLE_PRIVACY_MODAL_VISIBLE:
      // Toggles the privacy modal visible state.
      return { ...state, privacyModalVisible: !state.privacyModalVisible };
    case ActionType.TOGGLE_PERMISSION_MODAL_VISIBLE:
      // Toggles the permission modal visible state.
      return { ...state, permissionModalVisible: !state.permissionModalVisible };
    case ActionType.TOGGLE_SETTINGS_MODAL_VISIBLE:
      // Toggles the settings modal visible state.
      return { ...state, settingsModalVisible: !state.settingsModalVisible };
    case ActionType.TOGGLE_PRIVACY_ADDITIONAL_VISIBLE:
      // Toggles the additional privacy information on the landing page.
      return { ...state, landingPageAdditionalPrivacyVisible: !state.landingPageAdditionalPrivacyVisible };
    case ActionType.SHOW_CLOSE_MODAL:
      return {
        ...state,
        closeModalVisible: true,
        mobileContactModalVisible: false,
        settingsModalVisible: false,
        privacyModalVisible: false,
        errorModalVisible: false,
        closeModalTitle: action.payload?.closeModalTitle as string,
        closeModalBody: action.payload?.closeModalBody as string,
        closeModalSubtitle: (action.payload?.closeModalSubtitle as string) ?? "",
        closeModalHasTimer: (action.payload?.closeModalHasTimer as boolean) ?? false,
      };
    case ActionType.HIDE_CLOSE_MODAL:
      return {
        ...state,
        closeModalVisible: false,
        closeModalTitle: "",
        closeModalBody: "",
        timeLeft: TIMEOUT,
        timeoutMessageVisible: false,
      };
    case ActionType.HIDE_ALL_MODALS:
      return {
        ...state,
        closeModalVisible: false,
        mobileContactModalVisible: false,
        mobileTutorialModalVisible: false,
        settingsModalVisible: false,
        privacyModalVisible: false,
        errorModalVisible: false,
      };
    case ActionType.TOGGLE_MOBILE_MENU:
      return { ...state, mobileMenuVisible: !state.mobileMenuVisible };
    case ActionType.SHOW_ERROR_MODAL:
      return {
        ...state,
        errorModalVisible: true,
        errorModalTitle: action.payload?.errorTitle as string,
        errorModalBody: action.payload?.errorBody as string,
      };
    case ActionType.TOGGLE_MOBILE_CONTACT_VISIBLE:
      return { ...state, mobileContactModalVisible: !state.mobileContactModalVisible };
    case ActionType.TOGGLE_MOBILE_TUTORIAL_VISIBLE:
      return { ...state, mobileTutorialModalVisible: !state.mobileTutorialModalVisible };
    case ActionType.HIDE_ERROR_MODAL:
      return { ...state, errorModalVisible: false, errorModalTitle: "", errorModalBody: "" };
    case ActionType.START_CONVERSATION:
      return { ...state, landingPageVisible: false };
    case ActionType.START_RECORDING:
      return { ...state, recording: true, recordStart: dayjs(), spaceBarTutorialVisible: false };
    case ActionType.STOP_RECORDING:
      return {
        ...state,
        recording: false,
        sending: !(dayjs().diff(state.recordStart ?? dayjs()) <= TAP_THRESHOLD),
        spaceBarTutorialVisible: dayjs().diff(state.recordStart ?? dayjs()) <= TAP_THRESHOLD,
      };
    case ActionType.START_SENDING:
      return { ...state, sending: true };
    case ActionType.STOP_SENDING:
      return { ...state, sending: false };
    case ActionType.UPDATE_SELECTED_DEVICE:
      if (hasDefinedProperty(payload, "selectedDevice")) {
        // Set the state so we are ready for the next question
        return { ...state, selectedDevice: payload!.selectedDevice };
      }
      console.error(
        `[Reducer] We have received the ${ActionType.UPDATE_SELECTED_DEVICE} type but without the required 'selectedDevice' property on payload.`
      );
      return state;
    case ActionType.COUNTER_TICK:
      if (state.timeLeft === 0) {
        return { ...state, timeLeft: Math.max(0, state.timeLeft - 1000), timedOut: true };
      }
      if (state.timeLeft <= SHOW_TIMEOUT && !state.timeoutMessageVisible) {
        return { ...state, timeLeft: Math.max(0, state.timeLeft - 1000), timeoutMessageVisible: true };
      }

      return { ...state, timeLeft: Math.max(0, state.timeLeft - 1000), timeoutMessageVisible: false, timedOut: false };
    case ActionType.SET_SELECTED_MEDICINE:
      if (hasDefinedProperty(payload, "typeAheadSelectedMedicine")) {
        // Set the state so we are ready for the next question
        return { ...state, typeAheadSelectedMedicine: payload!.typeAheadSelectedMedicine };
      }
      console.error(
        `[Reducer] We have received the ${ActionType.SET_SELECTED_MEDICINE} type but without the required 'typeAheadSelectedMedicine' property on payload.`
      );
      return state;
    default:
      console.warn(`[REDUCER] The default case had been hit with the following action:`, action);
      console.warn(`\n The current state is: `, state);
      return state;
  }
};

export default reducer;
