import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import { ActionType } from "../../../../util/state/reducer";
import "./MobileBurger.css";

const MobileBurger = (): ReactElement => {
  const { state, dispatch } = useAppContext();
  const { mobileMenuVisible } = state;

  const handleBurgerTapped = () => {
    if (!mobileMenuVisible) dispatch({ type: ActionType.HIDE_ALL_MODALS });
    dispatch({ type: ActionType.TOGGLE_MOBILE_MENU });
  };

  return (
    <div>
      <button className={classNames("MobileBurger")} onClick={handleBurgerTapped}>
        <div
          className={classNames({
            "MobileBurgerFirst-closed": mobileMenuVisible,
            "MobileBurgerFirst-open": !mobileMenuVisible,
          })}
        />
        <div
          className={classNames({
            "MobileBurgerSecond-closed": mobileMenuVisible,
            "MobileBurgerSecond-open": !mobileMenuVisible,
          })}
        />
        <div
          className={classNames({
            "MobileBurgerLast-closed": mobileMenuVisible,
            "MobileBurgerLast-open": !mobileMenuVisible,
          })}
        />
      </button>
    </div>
  );
};

export default MobileBurger;
