import classNames from "classnames";
import React, { ReactElement } from "react";
import waveIcon from "../../../../assets/img/wave-icon.svg";
import stopIcon from "../../../../assets/img/stop-icon.svg";
import useAppContext from "../../../../util/helper";
import { ActionType } from "../../../../util/state/reducer";
import "./MobileSpeakButton.css";

const MobileSpeakButton = (): ReactElement => {
  const { state, dispatch, uneeq } = useAppContext();
  const { recording } = state;

  const handleButtonTapped = () => {
    if (!recording) {
      dispatch({ type: ActionType.START_RECORDING });
      uneeq?.current?.startRecording();
    } else {
      dispatch({ type: ActionType.STOP_RECORDING });
      dispatch({ type: ActionType.START_SENDING });
      uneeq.current.stopRecording();
    }
  };

  return (
    <div
      className={classNames("MobileSpeakButton", { "MobileSpeakButton recording": recording })}
      onClick={handleButtonTapped}
    >
      <div className={classNames("MobileSpeakButtonInner", { "MobileSpeakButtonInner recording": recording })}>
        <img src={!recording ? waveIcon : stopIcon} alt="Wave icon" />
      </div>
    </div>
  );
};

export default MobileSpeakButton;
