import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import "./Question.css";
import QuestionProps from "./Questionprops";

const Question = ({ question }: QuestionProps): ReactElement => {
  return (
    <div className="Question">
      <FontAwesomeIcon className="QuestionIcon" icon={["fas", "question-circle" as IconName]} size="lg" />
      <div className="QuestionText">{question}</div>
    </div>
  );
};

export default Question;
