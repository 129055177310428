import React, { ReactElement } from "react";
import parse from "html-react-parser";
import whiteLabelInformation from "../../../util/whitelabel";

import "./PrivacyDescription.css";
import togglePrivacy from "../../../assets/img/toggle-privacy-icon.svg";
import togglePrivacyOpen from "../../../assets/img/toggle-privacy-icon-open.svg";
import { ActionType } from "../../../util/state/reducer";
import useAppContext from "../../../util/helper";

const PrivacyDescription = (): ReactElement => {
  const {
    landingPage: { privacyTitle, privacyDescription, privacyDescriptionUnderlined },
  } = whiteLabelInformation;

  const { state, dispatch } = useAppContext();
  const { landingPageAdditionalPrivacyVisible } = state;

  /**
   * Toggles the additional privacy information.
   */
  const handlePrivacyToggled = () => {
    dispatch({ type: ActionType.TOGGLE_PRIVACY_ADDITIONAL_VISIBLE });
  };

  /**
   * Toggles the privacy modal shown/hidden state.
   */
  const handlePrivacyStatementClick = () => {
    dispatch({ type: ActionType.TOGGLE_PRIVACY_MODAL_VISIBLE });
  };

  return (
    <div className="PrivacyDescription">
      <p onClick={handlePrivacyToggled}>
        {parse(privacyTitle)}
        <img src={landingPageAdditionalPrivacyVisible ? togglePrivacyOpen : togglePrivacy} alt="Toggle Privacy " />
      </p>
      {landingPageAdditionalPrivacyVisible && (
        <p>
          {parse(privacyDescription)}
          <div onClick={handlePrivacyStatementClick}>{parse(privacyDescriptionUnderlined)}</div>
        </p>
      )}
    </div>
  );
};

export default PrivacyDescription;
