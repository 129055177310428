import React, { ReactElement, useReducer, useRef, useState } from "react";
import Modal from "react-modal";
import { Uneeq } from "uneeq-js";
import { htmlUpdates } from "../../util/helper";
import AppContext from "../../util/state/AppContext";
import initialState from "../../util/state/initialState";
import reducer from "../../util/state/reducer";
import LandingPage from "../LandingPage/LandingPage";
import MainPage from "../MainPage/MainPage";

const App = (): ReactElement => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // The Uneeq SDK uses these get/set state functions to hook into the avatar and local video DOM elements.
  // TODO: Rewrite to directly use refs, if possible.
  const [avatarVideo, setAvatarVideo] = useState();
  const [localVideo, setLocalVideo] = useState();
  const { landingPageVisible } = state;

  // The refs variable contains the references the Uneeq uses to set the avatar video and local video. Is passed into the AppContext.
  const refs = {
    localVideo,
    setLocalVideo,
    avatarVideo,
    setAvatarVideo,
  };

  // The uneeq SDK ref will live here.
  const uneeq = useRef<Uneeq>();

  // Make sure the Modal knows what the root element is (for the whole app). This is necessary for screenreaders (accessibility feature).
  Modal.setAppElement("#root");

  // Set HTML elements based on whitelabel.
  htmlUpdates();

  return (
    <AppContext.Provider value={{ state, dispatch, refs, uneeq }}>
      {landingPageVisible ? <LandingPage /> : <MainPage />}
    </AppContext.Provider>
  );
};

export default App;
