import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import "./MobileSuggestedLink.css";
import MobileSuggestedLinkProps from "./MobileSuggestedLinkProps";

const MobileSuggestedLink = ({
  label,
  url,
  highlighted,
  alt,
  iconName,
  iconPrefix,
}: MobileSuggestedLinkProps): ReactElement => {
  const { state } = useAppContext();
  const { ready } = state;

  const handleLinkClicked = () => {
    if (!ready) return;
    window.open(url);
  };

  const iconHTML =
    iconName !== undefined && iconPrefix !== undefined ? (
      <FontAwesomeIcon className="MobileSuggestedLinkIcon" icon={[iconPrefix as IconPrefix, iconName as IconName]} />
    ) : (
      ""
    );

  return (
    <div
      className={classNames("MobileSuggestedLink", { "MobileSuggestedLink pulse": highlighted })}
      key={label}
      onClick={handleLinkClicked}
      title={alt}
    >
      {/* TODO: use image from props instead of hardcoded */}
      {/* NOTE: For the SVG component below to change color, its class should be 'MobileSuggestedLinkIcon', its color set to 'currentColor' and the CSS must be in hex. */}
      {iconHTML}
      <span>{label}</span>
    </div>
  );
};

export default MobileSuggestedLink;
