import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import "./UneeqVideo.css";

interface Props {
  refName: string;
  className: string;
}

const UneeqVideo = ({ className, refName }: Props): ReactElement<Props> => {
  const { refs } = useAppContext();
  return <div className={className} ref={refs[refName]} />;
};

export default UneeqVideo;
