import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import "./MobileSuggestedQuestion.css";
import MobileSuggestedQuestionProps from "./MobileSuggestedQuestionProps";

const MobileSuggestedQuestion = ({
  label,
  highlighted,
  overrideBackgroundColorHex,
  overrideFontColorHex,
}: MobileSuggestedQuestionProps): ReactElement => {
  const { state, uneeq } = useAppContext();
  const { ready } = state;

  /**
   * Sends the clicked response to Uneeq.
   * @param {string} response Is the clicked response string.
   */
  const sendTextResponse = (response: string) => {
    if (!ready) return;

    uneeq.current.sendTranscript(response);
  };

  // Override styles if they're specified.
  let styleOverrides = {};
  if (overrideBackgroundColorHex) styleOverrides = { ...styleOverrides, backgroundColor: overrideBackgroundColorHex };
  if (overrideFontColorHex) styleOverrides = { ...styleOverrides, color: overrideFontColorHex };

  return (
    <div
      className={classNames("MobileSuggestedQuestion", { "MobileSuggestedQuestion pulse": highlighted })}
      style={styleOverrides}
      key={label}
      onClick={() => {
        sendTextResponse(label);
      }}
    >
      <span>{label}</span>
    </div>
  );
};

export default MobileSuggestedQuestion;
