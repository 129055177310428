import React, { ReactElement } from "react";
import parse from "html-react-parser";
import whiteLabelInformation from "../../../util/whitelabel";

import "./DescriptionComponent.css";

const DescriptionComponent = (): ReactElement => {
  const {
    landingPage: { description },
  } = whiteLabelInformation;

  return (
    <div className="DescriptionComponent">
      <p>{parse(description)}</p>
    </div>
  );
};

export default DescriptionComponent;
