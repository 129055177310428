import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import Select from "react-select";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./SettingsModal.css";

const SettingsModal = (): ReactElement => {
  const { dispatch, state, uneeq } = useAppContext();

  const {
    settingsModal: { title, microphoneSelectorExplanation, saveButton },
    modalCloseButton,
  } = whiteLabelInformation;
  const { devices, selectedDevice, settingsModalVisible } = state;

  /**
   * Handles the close button clicked event.
   */
  const handleCloseButtonClicked = () => {
    // Toggles the Settings Modal Shown/Hidden state
    dispatch({ type: ActionType.TOGGLE_SETTINGS_MODAL_VISIBLE });
  };

  /**
   * Handles the save button clicked event.
   * Sets the mic via the UneeqSDK and closes the settings modal.
   */
  const handleSaveButtonClicked = () => {
    // Saves the new device and then closes the modal
    if (selectedDevice?.value) {
      uneeq.current.setMic(selectedDevice.value);
    }
    handleCloseButtonClicked();
  };

  // Maps over the devices supplied by the UneeqSDK and maps them to a format that the Select component understands.
  let devicesList = [];
  if (devices?.audioInput) {
    devicesList = devices.audioInput.map((mediaDeviceInfo: Record<string, unknown>) => {
      return { value: mediaDeviceInfo.deviceId, label: mediaDeviceInfo.label };
    });
  }

  return (
    <Modal
      isOpen={settingsModalVisible}
      onRequestClose={handleCloseButtonClicked}
      className="SettingsModal Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="SettingsModalTitle">{parse(title)}</p>
      <div className="SettingsModalBody">
        {parse(microphoneSelectorExplanation)}
        <Select
          className="SettingsModalSelect"
          defaultValue={selectedDevice}
          onChange={(deviceSelected) => {
            dispatch({
              type: ActionType.UPDATE_SELECTED_DEVICE,
              payload: { selectedDevice: deviceSelected },
            });
          }}
          options={devicesList}
          label="Selecteer uw microfoon"
        />
      </div>
      <p className="SettingsModalSubText"></p>
      <div className="SettingsModalButtonContainer">
        <button className="SettingsButtonSave" onClick={handleSaveButtonClicked}>
          {parse(saveButton)}
        </button>
        <button className="SettingsButtonClose" onClick={handleCloseButtonClicked}>
          {parse(modalCloseButton)}
        </button>
      </div>
    </Modal>
  );
};

export default SettingsModal;
