import React, { ReactElement } from "react";
import parse from "html-react-parser";
import whiteLabelInformation from "../../../util/whitelabel";

import "./TitleComponent.css";

const TitleComponent = (): ReactElement => {
  const {
    landingPage: { title },
  } = whiteLabelInformation;

  return (
    <div className="TitleComponent">
      <p>{parse(title)}</p>
    </div>
  );
};

export default TitleComponent;
