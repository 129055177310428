import React, { ReactElement } from "react";
import whiteLabelInformation from "../../../util/whitelabel";

import "./LandingPageLogos.css";

const LandingPageLogos = (): ReactElement => {
  const {
    imagePaths: { firstLogoPath, secondLogoPath, thirdLogoPath },
  } = whiteLabelInformation;

  // Shows the logo's based on the paths in the whitelabel JSON. If the path's empty, it's not shown.
  return (
    <div className="LandingPageLogos">
      {firstLogoPath !== "" && <img src={firstLogoPath} alt="First logo" />}
      {secondLogoPath !== "" && <img src={secondLogoPath} alt="Second logo" />}
      {thirdLogoPath !== "" && <img src={thirdLogoPath} alt="Third logo" />}
    </div>
  );
};

export default LandingPageLogos;
