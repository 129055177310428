import React, { ReactElement } from "react";
import whiteLabelInformation from "../../../util/whitelabel";
import "./LogoComponent.css";

const LogoComponent = (): ReactElement | null => {
  const {
    imagePaths: { primaryLogoPath },
  } = whiteLabelInformation;
  const isVisible = primaryLogoPath;

  return isVisible ? (
    <div className="LogoComponentWrapper">
      <div className="LogoComponent">
        <img src={primaryLogoPath} alt="Logo" />
      </div>
    </div>
  ) : null;
};

export default LogoComponent;
