import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import MobileBurger from "./MobileBurger/MobileBurger";
import MobileHeaderText from "./MobileHeaderText/MobileHeaderText";
import "./MobileTopBar.css";

const MobileTopBar = (): ReactElement => {
  const { state } = useAppContext();
  const { ready } = state;

  return (
    <div className={classNames("MobileTopBar", { "MobileTopBar hidden": !ready })}>
      <MobileBurger />
      <MobileHeaderText />
    </div>
  );
};

export default MobileTopBar;
