import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./PrivacyModal.css";

const PrivacyModal = (): ReactElement => {
  const { state, dispatch } = useAppContext();

  const {
    privacyModal: { title, body, subText, subTextUnderlined },
    privacy: { privacyStatementURL },
    modalCloseButton,
  } = whiteLabelInformation;

  const { privacyModalVisible } = state;
  const privacyURL = privacyStatementURL !== "" ? privacyStatementURL : window.location;

  const handleCloseButtonClicked = () => {
    // Toggles the Privacy Modal Visible/Hidden state
    dispatch({ type: ActionType.TOGGLE_PRIVACY_MODAL_VISIBLE });
  };

  return (
    <Modal
      isOpen={privacyModalVisible}
      onRequestClose={handleCloseButtonClicked}
      className="Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="PrivacyModalTitle">{parse(title)}</p>
      <p className="PrivacyModalBody">{parse(body)}</p>
      <p className="PrivacyModalSubText">
        {parse(subText)} <a href={privacyURL}>{parse(subTextUnderlined)}</a>
      </p>
      <button className="PrivacyModalButton" onClick={handleCloseButtonClicked}>
        {parse(modalCloseButton)}
      </button>
    </Modal>
  );
};

export default PrivacyModal;
