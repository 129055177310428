import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement, useState } from "react";
import { useCombobox } from "downshift";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import { ActionType } from "../../../util/state/reducer";

// Load in the medicines list
import rawMedicineList from "../../../resources/medicines.json";
import "./MobileTypeAhead.css";

const MobileTypeAhead = (): ReactElement => {
  const { dispatch, state, uneeq } = useAppContext();
  const { ready } = state;

  const {
    typeAhead: { headerText, sendButtonText },
  } = whiteLabelInformation;

  // Force the medicines list into an alphabetical order, takes localization into account.
  const medicineList = rawMedicineList.sort((a, b) => a.localeCompare(b));

  // Determines the MobileTypeAhead text based on the state.
  const [inputItems, setInputItems] = useState(medicineList);
  const sendMedicineChoice = () => {
    uneeq.current.sendTranscript(state.typeAheadSelectedMedicine);
  };

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(medicineList.filter((medicine) => medicine.toLowerCase().startsWith(inputValue!.toLowerCase())));
    },
    onSelectedItemChange: ({ selectedItem: selectedMedicine }) =>
      dispatch({
        type: ActionType.SET_SELECTED_MEDICINE,
        payload: { typeAheadSelectedMedicine: selectedMedicine },
      }),
  });

  return (
    <div className={classNames("MobileTypeAheadWrapper", { "MobileTypeAheadWrapper hidden": !ready })}>
      <div className="MobileTypeAheadResultListWrapper">
        <ul {...getMenuProps()} className="MobileTypeAheadResultList">
          {isOpen &&
            inputItems.map((item, index) => (
              <li
                style={highlightedIndex === index ? { backgroundColor: "rgba(87, 95, 114, 1)", color: "white" } : {}}
                key={`${item}${index}`}
                {...getItemProps({ item, index })}
                className="MobileTypeAheadResultListLi"
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
              </li>
            ))}
        </ul>
      </div>
      <div className="MobileTypeAhead">
        <div className="MobileTypeAheadHeader">{parse(headerText)}</div>
        <div {...getComboboxProps()} className="MobileTypeAheadInputWrapper">
          <input {...getInputProps()} className="MobileTypeAheadInput" />
          <button
            type="button"
            {...getToggleButtonProps()}
            aria-label={"toggle menu"}
            className="MobileTypeAheadButton"
          >
            {isOpen ? <>&#8595;</> : <>&#8593;</>}
          </button>
        </div>
        <button className="MobileTypeAheadSendButton" onClick={sendMedicineChoice}>
          {parse(sendButtonText)}
        </button>
      </div>
    </div>
  );
};

export default MobileTypeAhead;
