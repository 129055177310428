import { AppState } from "./initialState";
import { hasDefinedProperty } from "../helper";
import { TIMEOUT } from "../../config/config";

// This enum encapsulates the Uneeq message types
enum UneeqMessageType {
  READY = "Ready",
  AVATARQUESTIONTEXT = "AvatarQuestionText",
  AVATARANSWERTEXT = "AvatarAnswerText",
  AVATARAVAILABLE = "AvatarAvailable",
  DEVICELISTUPDATED = "DeviceListUpdated",
  SETMICSUCCESS = "SetMicSuccess",
  SETCAMERASUCCESS = "SetCameraSuccess",
  SETSPEAKERSUCCESS = "SetSpeakerSuccess",
  SESSIONENDED = "SessionEnded",
  DEVICEPERMISSIONALLOWED = "DevicePermissionAllowed",
  AVATARANSWER = "AvatarAnswer",
  AVATARTEXTINPUTFINISHED = "AvatarTextInputFinished",
  SESSIONLIVE = "SessionLive",
}
// The general reducer action types.
enum UneeqActionType {
  UNEEQMESSAGE = "uneeqMessage",
}

export interface UneeqPayload {
  uneeqMessageType: UneeqMessageType | string;
  question?: string;
  answerAvatar?: string;
  answerSpeech?: string;
  devices?: Record<string, unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
export interface UneeqAction {
  type: UneeqActionType;
  payload?: UneeqPayload;
  uneeqAction: boolean;
}

/**
 * Deals with the specific Reducer case with the type `UneeqMessage`.
 *
 * @param {UneeqAppstate} state The state as passed to the main reducer.
 * @param {UneeqAction} action The action as passed by the main reducer.
 *
 * @returns {UneeqAppState} Returns an updated appstate.
 */
const uneeqMessageHandler = (state: AppState, action: UneeqAction): AppState => {
  const { payload } = action;
  if (payload?.uneeqMessageType !== "WebRtcData") {
    // Temp logging
    console.info(
      `[UneeqMessageHandler] Uneeq Message received of type "${payload?.uneeqMessageType}" with the following action:`,
      action,
      state
    );
  }

  switch (payload?.uneeqMessageType) {
    case UneeqMessageType.READY:
      return state;
    case UneeqMessageType.AVATARQUESTIONTEXT:
      // We have received the asked question back from speech2text, set it in the state to be displayed.
      if (hasDefinedProperty(payload, "question")) {
        // Set the state so we are ready for the next question
        return { ...state, lastUtterance: payload.question!, sending: false, recording: false, timeLeft: TIMEOUT };
      }

      console.error(
        `[UMH] We have received the ${UneeqMessageType.AVATARQUESTIONTEXT} type but without the required 'question' property on payload.`
      );

      if (!state.spaceBarTutorialShown) {
        return { ...state, spaceBarTutorialShown: true, spaceBarTutorialVisible: true };
      }
      return { ...state };

    case UneeqMessageType.AVATARANSWERTEXT:
      return state;
    case UneeqMessageType.AVATARAVAILABLE:
      return { ...state, unavailable: false };
    case UneeqMessageType.DEVICELISTUPDATED:
      if (hasDefinedProperty(payload, "devices")) {
        // The UneewSDK has provided the app with a updated device list.
        return { ...state, devices: payload.devices! };
      }
      console.error(
        `[UMH] We have received the ${UneeqMessageType.AVATARQUESTIONTEXT} type but without the required 'devices' property on payload.`
      );
      return state;
    case UneeqMessageType.SETMICSUCCESS:
    case UneeqMessageType.SETCAMERASUCCESS:
    case UneeqMessageType.SETSPEAKERSUCCESS:
      //  TODO: Implement when implementing device selection
      return state;
    case UneeqMessageType.SESSIONENDED:
      return { ...state, ready: false, sessionEnded: true, timeLeft: 0, timeoutMessageVisible: true, timedOut: true };
    case "SessionError":
    case "ErrorEndingSession":
    case "MicActivityError":
    case "DeviceNotFoundError":
    case "AvatarUnavailable":
    case "ConnectionLost":
    case "Warning":
    case "DeviceError":
      //  TODO: Implement error handling
      console.error(
        `[UMH] We have received some sort of error from the Uneeq API, the payload and pre action state:`,
        payload
      );
      return state;
    case UneeqMessageType.DEVICEPERMISSIONALLOWED:
      return { ...state, permissionAllowed: true };
    case UneeqMessageType.AVATARANSWER:
      console.log(`Entered avatar answer with payload: ${payload.answerAvatar}`);
      // Parse the FAQ
      if (hasDefinedProperty(payload, "answerAvatar")) {
        try {
          const answer = JSON.parse(payload.answerAvatar as string);

          const frontendPayload = JSON.parse(answer?.instructions?.displayHtml?.html);
          let appendState = {};
          if (frontendPayload && frontendPayload.suggested) {
            appendState = { ...appendState, actionListResponses: frontendPayload.suggested };
          } else {
            appendState = { ...appendState, actionListResponses: undefined };
          }
          // Check for the 'medicijnprompt' flag in the answer
          if (payload?.answer?.indexOf("<frontend:medicijnprompt>") !== -1) {
            // The incoming AvatarAnswer is an autogenerated prompt from Dialogflow for the medicine name.
            if (state.medicineNamePromptCount >= 1) {
              // If we have already received two medicinePrompts then we now need to show the TypeAhead.
              // Also zero out the promptCount so we don't reshow the TypeAhead on the next prompt.
              // Increment the counter so we can reshow the typeAhead if the user doesn't enter a valid medicine.
              appendState = {
                ...appendState,
                showTypeAhead: true,
                medicineNamePromptCount: state.medicineNamePromptCount + 1,
              };
            } else {
              // Increase the count by one.
              appendState = {
                ...appendState,
                showTypeAhead: false,
                medicineNamePromptCount: state.medicineNamePromptCount + 1,
              };
            }
          } else {
            // If the current message doesn't contain the medicijnprompt flag then we can clear out the count and hide the TypeAhead.
            appendState = { ...appendState, showTypeAhead: false, medicineNamePromptCount: 0 };
          }
          if (frontendPayload && frontendPayload?.followupQuestion) {
            // Save the followupQuestion into the state so we can show the buttons in the UI
            // Null out the actionListResponses because they can't coexist with the followup-question
            appendState = {
              ...appendState,
              actionListResponses: undefined,
              followupQuestion: frontendPayload.followupQuestion,
            };
          } else {
            appendState = { ...appendState, followupQuestion: undefined };
          }
          return { ...state, ...appendState, timeLeft: TIMEOUT };
        } catch (error) {
          console.warn(
            `[UneeqReducer] we have received the ${UneeqMessageType.AVATARANSWER} case but the passed payload contains invalid JSON or is: `,
            payload
          );
          console.error("[UneeqReducer] The generated error:", error);
        }

        console.warn(
          `[UMH] We have received the ${UneeqMessageType.AVATARQUESTIONTEXT} type but with invalid actionListResponses payload.`
        );
        return { ...state, showTypeAhead: false, medicineNamePromptCount: 0 };
      }

      console.warn(
        `[UMH] We have received the ${UneeqMessageType.AVATARQUESTIONTEXT} type but without the required 'answerAvatar' property on payload.`
      );
      return state;

    case UneeqMessageType.AVATARTEXTINPUTFINISHED:
      return { ...state, question: "" };
    case UneeqMessageType.SESSIONLIVE:
      return { ...state, ready: true, timeLeft: TIMEOUT };
    default:
      return state;
  }
};

export default uneeqMessageHandler;
