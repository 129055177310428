import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import commandKeyIcon from "../../../assets/img/command-key.svg";
import spacebarKeyIcon from "../../../assets/img/spacebar.svg";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import "./SpaceBarTutorial.css";

const SpaceBarTutorial = (): ReactElement => {
  const { state } = useAppContext();
  const { ready } = state;
  const {
    spacebarTutorial: { title, body },
  } = whiteLabelInformation;

  return (
    <div className="SpaceBarTutorialWrapper">
      <div className={classNames("SpaceBarTutorial", { "SpaceBarTutorial hidden": !ready })}>
        <span className="SpaceBarTutorialTitle">{parse(title)}</span>
        <span className="SpaceBarTutorialBody">{parse(body)}</span>
        <div className="SpaceBarTutorialImages">
          <img className="CommandKeyIcon" src={commandKeyIcon} alt="Command Key Icon" />
          <img className="SpaceBarIcon" src={spacebarKeyIcon} alt="Spacebar Key Icon" />
          <img className="CommandKeyIcon" src={commandKeyIcon} alt="Command Key Icon" />
        </div>
      </div>
    </div>
  );
};

export default SpaceBarTutorial;
