import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import "./ErrorModal.css";

const ErrorModal = (): ReactElement => {
  const { state, dispatch } = useAppContext();

  const { modalCloseButton } = whiteLabelInformation;
  const { errorModalTitle, errorModalBody, errorModalVisible } = state;

  /**
   * Toggles the error modal shown/hidden state.
   */
  const handleCloseButtonClicked = () => {
    dispatch({ type: ActionType.HIDE_ERROR_MODAL });
  };

  return (
    <Modal
      isOpen={errorModalVisible}
      onRequestClose={handleCloseButtonClicked}
      className="Modal"
      overlayClassName="ModalOverlay"
    >
      <p className="ErrorModalTitle">{parse(errorModalTitle)}</p>
      <p className="ErrorModalBody">{parse(errorModalBody)}</p>
      <button className="ErrorModalButton" onClick={handleCloseButtonClicked}>
        {parse(modalCloseButton)}
      </button>
    </Modal>
  );
};

export default ErrorModal;
