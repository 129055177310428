import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import "./SuggestedLink.css";
import SuggestedLinkProps from "./SuggestedLinkProps";
import "@fortawesome/free-solid-svg-icons";

const SuggestedLink = ({ label, url, highlighted, alt, iconName, iconPrefix }: SuggestedLinkProps): ReactElement => {
  const { state } = useAppContext();
  const { ready } = state;

  /**
   * Opens the url of the suggested link.
   */
  const handleLinkClicked = () => {
    if (!ready) return;
    window.open(url);
  };

  const iconHTML =
    iconName !== undefined && iconPrefix !== undefined ? (
      <FontAwesomeIcon className="icon" icon={[iconPrefix as IconPrefix, iconName as IconName]} />
    ) : (
      ""
    );

  return (
    <div
      className={classNames("SuggestedLink", { "SuggestedLink pulse": highlighted })}
      key={label}
      onClick={handleLinkClicked}
      title={alt}
    >
      <span>{label}</span>
      {iconHTML}
    </div>
  );
};

export default SuggestedLink;
