import React, { ReactElement } from "react";
import useAppContext from "../../../../util/helper";
import AnswerButtonsGroup from "../AnswerButtonsGroup/AnswerButtonsGroup";
import "./AnswerContainer.css";

const AnswerContainer = (): ReactElement => {
  const { state } = useAppContext();
  const { followupQuestion } = state;
  // Only show the element if there actually is a FollowupQuestion
  if (followupQuestion?.question) {
    return (
      <div className="AnswerContainer">
        <AnswerButtonsGroup question={followupQuestion.question} answers={followupQuestion.answerOptions} />
      </div>
    );
  }
  return <div></div>;
};

export default AnswerContainer;
