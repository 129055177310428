import React, { ReactElement } from "react";
import whiteLabelInformation from "../../util/whitelabel";
import "../MainPage/Modal.css";
import PrivacyModal from "../WhiteLabelComponents/PrivacyModal/PrivacyModal";
import DescriptionComponent from "./DescriptionComponent/DescriptionComponent";
import "./LandingPage.css";
import LandingPageLogos from "./LandingPageLogos/LandingPageLogos";
import PermissionModal from "./PermissionModal/PermissionModal";
import PrivacyDescription from "./PrivacyDescription/PrivacyDescription";
import StartButton from "./StartButton/StartButton";
import TitleComponent from "./TitleComponent/TitleComponent";

const LandingPage = (): ReactElement => {
  const {
    imagePaths: { landingPageImagePath, firstLogoPath, secondLogoPath, thirdLogoPath },
  } = whiteLabelInformation;

  // When there's at least one logo path in the whitelabel, the LandingPageLogos component can be rendered.
  const atLeastOneLogo = firstLogoPath !== "" || secondLogoPath !== "" || thirdLogoPath !== "";

  return (
    <div className="LandingPage">
      <TitleComponent />
      <img className="ImageComponent" src={landingPageImagePath} alt="Landing page" />
      {atLeastOneLogo && <LandingPageLogos />}
      <DescriptionComponent />
      <PrivacyDescription />
      <StartButton />
      <PermissionModal />
      <PrivacyModal />
    </div>
  );
};

export default LandingPage;
