import classNames from "classnames";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import "./MobileBottomBar.css";
import MobileSpeakButton from "./MobileSpeakButton/MobileSpeakButton";
import { ReactComponent as BottomCurve } from "../../../assets/img/mobile-bottom-bar-curve.svg";
import MobileExplainerButton from "./MobileExplainerButton/MobileExplainerButton";
import whiteLabelInformation from "../../../util/whitelabel";
import MobileAnswerButtonsGroup from "./MobileAnswers/MobileAnswerButtonsGroup/MobileAnswerButtonsGroup";

const MobileBottomBar = (): ReactElement => {
  const { state } = useAppContext();
  const { ready, followupQuestion, showTypeAhead } = state;

  const {
    mobileTutorial: { title, body },
  } = whiteLabelInformation;

  const showExplainerButton = title && body;
  const showAnswerButtons = followupQuestion !== undefined;

  const VoiceBottomBar = (
    <div className={classNames("MobileBottomBar", { "MobileBottomBar hidden": !ready })}>
      <div className="MobileBottomStroke" />
      <BottomCurve className="BottomCurve" />
      <MobileSpeakButton />
      {showExplainerButton && <MobileExplainerButton />}
    </div>
  );

  const SurveyQuestionBottomBar = (
    <div className={classNames("MobileBottomBarQuestion", { "MobileBottomBarQuestion hidden": !ready })}>
      <div className="MobileBottomStroke">
        {showAnswerButtons && (
          <MobileAnswerButtonsGroup question={followupQuestion.question} answers={followupQuestion.answerOptions} />
        )}
      </div>
    </div>
  );
  const TypeAheadBottomBar = <div className={classNames("MobileBottomBarQuestion showTypeAhead")}></div>;

  // Showing the TypeAhead supersedes all other bottom bars.
  if (showTypeAhead) {
    return TypeAheadBottomBar;
  }
  return showAnswerButtons ? SurveyQuestionBottomBar : VoiceBottomBar;
};

export default MobileBottomBar;
